import { lazy } from 'react';

//project import
import Loadable from '../components/Loadable';
import MinimalLayout from '../layout/MinimalLayout';

const LazyLogin = Loadable(
  lazy(() => import('../components/authentication/login')),
);
const LazyRegister = Loadable(
  lazy(() => import('../components/authentication/register')),
);
const LazyLogout = Loadable(
  lazy(() => import('../components/authentication/logout')),
);
const LazyPasswordReset = Loadable(
  lazy(() => import('../components/authentication/password_reset')),
);
const LazyActivateAccount = Loadable(
  lazy(() => import('../components/authentication/account-activation')),
);
const LazyCheckEmail = Loadable(
  lazy(() => import('../components/authentication/CheckEmail')),
);
const LazyActivationSuccess = Loadable(
  lazy(() => import('../components/authentication/ActivationSuccess')),
);
const LazyActivationFailed = Loadable(
  lazy(() => import('../components/authentication/ActivationFailed')),
);
const LazySSOContinue = Loadable(
  lazy(() => import('../components/authentication/SSOContinueAsPage')),
);
//const LazySSO = Loadable(lazy(() => import('../components/authentication/login-sso')));
// ==============================|| AUTH ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <LazyLogin />,
    },
    //{
    //    path: 'sso-login',
    //    element: <LazySSO />
    //},
    {
      path: 'register',
      element: <LazyRegister />,
    },
    {
      path: 'logout',
      element: <LazyLogout />,
    },
    {
      path: 'resend-activation',
      element: <LazyActivateAccount />,
    },
    {
      path: 'check-email-activation',
      element: <LazyCheckEmail />,
    },
    {
      path: 'activation-success',
      element: <LazyActivationSuccess />,
    },
    {
      path: 'activation-failed',
      element: <LazyActivationFailed />,
    },
    {
      path: 'sso-continue',
      element: <LazySSOContinue />,
    },
    {
      path: 'password-reset',
      element: <LazyPasswordReset />,
    },
  ],
};

export default AuthenticationRoutes;
