import { createTheme } from '@mui/material/styles';

// Define the base theme
const baseTheme = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: '2.5rem',
      letterSpacing: '0.0075em',
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 700,
      lineHeight: '2.75rem',
      letterSpacing: '0.0075em',
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '2.5rem',
      letterSpacing: '0.0075em',
    },
    h4: {
      fontSize: '1.75rem',
      fontWeight: 400,
      lineHeight: '2.25rem',
      letterSpacing: '0.0075em',
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '2rem',
      letterSpacing: '0.0075em',
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: '1.75rem',
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
      letterSpacing: '0.0075em',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
      letterSpacing: '0.0075em',
    },
    button: {
      textTransform: 'none',
      lineHeight: '1rem',
      letterSpacing: '0.0075em',
      fontWeight: 500,
    },
    footerLinks: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        },
      },
    },
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    duration: {
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
});

export default baseTheme;
