import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import AuthContext from './statemanagement/AuthContext';
import { ThemeProvider } from '@mui/material/styles';
import Routes from './modules/routes';
import Nav from './modules/views/navigation/Header/index';
import ErrorBoundary from './modules/pages/ErrorPage';
import { CssBaseline } from '@mui/material';
import { ThemeContext } from './styling/themes/themeContext';
//import lightTheme from './styling/themes/lightTheme';
import darkTheme from './styling/themes/darkTheme';
import Footer from './modules/components/Footer';

function App() {
  const [theme, setTheme] = useState(darkTheme);
  const { isAuthenticated, getUserProfileInfo } = useContext(AuthContext); // Combined the two useContext calls.

  useEffect(() => {
    if (isAuthenticated) {
      getUserProfileInfo();
    }
  }, [isAuthenticated]);

  // Component used for testing of throwing Errors.
  //const ThrowErrorComponent = () => {
  //  throw new Error('This is a test error for the ErrorBoundary');
  //  return null;
  //};

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
            }}
          >
            <Nav />
            <div style={{ flex: '1', marginTop: 50 }}>
              <Routes />
            </div>
            <Footer />
          </div>
        </ErrorBoundary>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default App;
