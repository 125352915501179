import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import {
  lightBlue,
  lightGreen,
  blueGrey,
  grey,
  yellow,
  green,
  red,
} from '@mui/material/colors';

const lightTheme = createTheme(baseTheme, {
  palette: {
    primary: {
      main: lightBlue[600],
    },
    secondary: {
      main: lightGreen[400],
    },
    background: {
      default: blueGrey[50],
    },
    text: {
      primary: grey[900],
      secondary: grey[700],
    },
    success: {
      main: green[500],
    },
    info: {
      main: lightBlue[500],
    },
    warning: {
      main: yellow[700],
    },
    error: {
      main: red[500],
    },
  },
});

export default lightTheme;
