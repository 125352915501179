import React, { useState, useEffect } from 'react';
import {
  TextField,
  Dialog,
  DialogContent,
  Box,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  ListItemIcon,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import GetData from '../../services/datafetching/GetData'; // Import your GetData function
import Highlighter from 'react-highlight-words';
import '../../css/SearchBarNav.css';
import PropTypes from 'prop-types';

SearchBar.propTypes = {
  width: PropTypes.string,
  displayType: PropTypes.string,
};

SearchBar.defaultProps = {
  displayType: 'full',
  width: '200px',
};

function SearchBar({ displayType }) {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New state variable for loading state
  const [hasSearched, setHasSearched] = useState(false); // New state variable for tracking if a search has been performed
  const [searchHistory, setSearchHistory] = useState(() => {
    const saved = localStorage.getItem('searchHistory');
    return saved ? JSON.parse(saved) : [];
  }); // Initialize state from local storage
  const [favorites, setFavorites] = useState(() => {
    const saved = localStorage.getItem('favorites');
    return saved ? JSON.parse(saved) : [];
  });

  useEffect(() => {
    sessionStorage.setItem('searchHistory', JSON.stringify(searchHistory)); // Store search history in session storage
  }, [searchHistory]);

  useEffect(() => {
    sessionStorage.setItem('favorites', JSON.stringify(favorites)); // Store favorites in session storage
  }, [favorites]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = async (event, term = searchTerm) => {
    event.preventDefault();
    if (term.length < 3) {
      setErrorMessage('Your search term is too short.');
      return;
    }
    setIsLoading(true); // Set loading state to true when search starts
    GetData(`/api/v1/search?q=${term}`).then(({ isSuccess, data, error }) => {
      setIsLoading(false); // Set loading state to false when search ends
      setHasSearched(true); // Set hasSearched to true after a search has been performed
      if (isSuccess) {
        setSearchResults(data.posts);
        setErrorMessage('');
        setSearchHistory((prevHistory) => {
          const updatedHistory = [
            term,
            ...prevHistory.filter((t) => t !== term),
          ].slice(0, 10);
          localStorage.setItem('searchHistory', JSON.stringify(updatedHistory)); // Save to local storage
          return updatedHistory;
        });
      } else {
        console.log(error);
        setErrorMessage('An error occurred while searching. Please try again.'); // Set error message if there's an error
      }
    });
  };

  const handleRemoveFromHistory = (term) => {
    setSearchHistory((prevHistory) => {
      const updatedHistory = prevHistory.filter((t) => t !== term);
      localStorage.setItem('searchHistory', JSON.stringify(updatedHistory)); // Save to local storage
      return updatedHistory;
    });
  };

  const handleAddToFavorites = (term) => {
    setFavorites((prevFavorites) => {
      const updatedFavorites = [
        term,
        ...prevFavorites.filter((t) => t !== term),
      ];
      localStorage.setItem('favorites', JSON.stringify(updatedFavorites)); // Save to local storage
      return updatedFavorites;
    });
  };

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
    >
      {displayType === 'full' ? (
        <OutlinedInput
          variant="outlined"
          sx={{
            bgcolor: 'lightgray',
            width: '100%', // Full width on smaller screens
            maxWidth: '800px', // Maximum width on larger screens
            margin: '0 auto', // Center the search bar
          }}
          size="small"
          placeholder="search..."
          onClick={handleOpen}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="perform search"
                onClick={handleOpen}
                edge="end"
              >
                <SearchOutlinedIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      ) : (
        <IconButton aria-label="perform search" onClick={handleOpen}>
          <SearchOutlinedIcon />
        </IconButton>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <form onSubmit={handleSearch}>
            <TextField
              autoFocus
              margin="dense"
              id="search"
              label="Search"
              type="search"
              fullWidth
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <IconButton type="submit">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}
            {isLoading && <Typography>Loading...</Typography>}{' '}
            {/* Display loading message */}
            {searchResults.length === 0 && !isLoading && hasSearched && (
              <Typography>No results found</Typography>
            )}{' '}
            {/* Display no results message */}
            <Box
              sx={{ display: 'flex', overflowY: 'auto', maxHeight: '400px' }}
            >
              <List sx={{ mt: 2, width: '100%' }}>
                {searchResults.map((result) => (
                  <ListItem
                    key={result.id}
                    button
                    component="a"
                    href={`https://community.crushingsecurity.com/t/${result.topic_id}`}
                    sx={{
                      mb: 1,
                      bgcolor: 'white',
                      '&:hover': {
                        bgcolor: 'grey.300',
                      },
                    }}
                  >
                    <ListItemText
                      primary={
                        <Highlighter
                          searchWords={[searchTerm]}
                          autoEscape={true}
                          textToHighlight={result.name}
                        />
                      }
                      secondary={
                        <Highlighter
                          searchWords={[searchTerm]}
                          autoEscape={true}
                          textToHighlight={`${new Date(
                            result.created_at,
                          ).toLocaleDateString()} - ${result.blurb}`}
                        />
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="go to post"
                        href={`https://community.crushingsecurity.com/t/${result.topic_id}`}
                      >
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Search History
            </Typography>
            {searchHistory.map((term, index) => (
              <ListItem
                key={index}
                button
                sx={{
                  '&:hover': {
                    bgcolor: 'grey.300',
                  },
                }}
              >
                <ListItemIcon>
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText
                  primary={term}
                  onClick={(event) => handleSearch(event, term)}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="remove from history"
                    onClick={() => handleRemoveFromHistory(term)}
                  >
                    <CloseIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="add to favorites"
                    onClick={() => handleAddToFavorites(term)}
                  >
                    <StarIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <Typography variant="h6" sx={{ mt: 2 }}>
              Favorites
            </Typography>
            {favorites.map((term, index) => (
              <ListItem
                key={index}
                button
                sx={{
                  '&:hover': {
                    bgcolor: 'grey.300',
                  },
                }}
              >
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText
                  primary={term}
                  onClick={(event) => handleSearch(event, term)}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="remove from favorites"
                    onClick={() =>
                      setFavorites((prevFavorites) =>
                        prevFavorites.filter((t) => t !== term),
                      )
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default SearchBar;
