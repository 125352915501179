import { lazy } from 'react';
import React from 'react';
//project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';

// render - pages
const LazyHomePage = Loadable(lazy(() => import('../pages/HomePage')));
const LazyHomePageAuthenticated = Loadable(
  lazy(() => import('../pages/HomePageAuthenticated')),
);
const LazyBlogPage = Loadable(lazy(() => import('../pages/blogs/BlogPage')));
const LazyProfileSettingsPage = Loadable(
  lazy(() => import('../pages/account/SettingsPage')),
);
const LazyAppsPage = Loadable(lazy(() => import('../pages/apps/AppsHome')));
const LazyPrivacyPage = Loadable(
  lazy(() => import('../pages/privacy/Privacy')),
);
const LazyCommunityHomePage = Loadable(
  lazy(() => import('../pages/community/CommunityHomePage')),
);
const LazyNewsletterSignupPage = Loadable(
  lazy(() => import('../pages/newsletter/NewsLetterSignupPage')),
);
const LazyUnsubscribePage = Loadable(
  lazy(() => import('../components/authentication/UnsubscribePage')),
);
const LazySupportPage = Loadable(lazy(() => import('../pages/SupportPage')));
const LazyWelcomePage = Loadable(lazy(() => import('../pages/WelcomePage')));
const LazyAboutPage = Loadable(lazy(() => import('../components/About')));

const LazyResourcesPage = Loadable(
  lazy(() => import('../components/ResourcesPage')),
);

const LazyEngageCommunity = Loadable(
  lazy(() => import('../components/ConnectAndEngage')),
);

// Landing Pages
const LazyLandingForumPage = Loadable(
  lazy(() => import('../pages/LandingPageForum')),
);

// ==============================|| MAIN ROUTING ||============================== //

/**
 * MainRoutes Component Documentation
 *
 * This component contains the primary routes for the application. The routes
 * are determined based on whether a user is authenticated or not.
 *
 * ## Route Overview:
 *
 * `/`: The default landing page. If authenticated, users see the authenticated homepage; otherwise, the regular homepage.
 * `/apps`: Main page for all applications.
 * `/apps/cyberlibrary`: Access the Cyber Library page for various cyber resources.
 * `/commands`: Displays the main command table where users view a list of commands.
 * `/community`: A hub for community interaction.
 * `/profile`: User profile settings page.
 * `/blogs`: Central page for blogs.
 * `/welcome`: A guide page for newcomers.
 * `/marketplace`: Showcase of products or services for sale.
 * `/privacy`: Privacy policy page.
 * `/zone/:zoneid`: View a specific zone using its ID.
 * `/zone/:zoneid/page/:pageid`: View a specific page inside a zone, identified by the zone and page IDs.
 * `/newsletter-signup`: Page for newsletter sign-ups.
 * `/unsubscribe-newsletter`: Opt-out page for the newsletter.
 * `/support`: User support page.
 *
 * Note: The route `/blog/:slug` is currently unused but could be implemented in the future to view individual
 * blog posts based on their slug.
 */

const MainRoutes = ({ isAuthenticated }) => ({
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: isAuthenticated ? (
        <LazyHomePageAuthenticated />
      ) : (
        <LazyHomePage />
      ),
    },
    { path: 'about', element: <LazyAboutPage /> },
    { path: 'apps', element: <LazyAppsPage /> },
    { path: 'community', element: <LazyCommunityHomePage /> },
    { path: 'connect', element: <LazyEngageCommunity /> },
    { path: 'profile', element: <LazyProfileSettingsPage /> },
    { path: 'blogs', element: <LazyBlogPage /> },
    { path: 'welcome', element: <LazyWelcomePage /> },
    { path: 'privacy', element: <LazyPrivacyPage /> },
    { path: 'newsletter-signup', element: <LazyNewsletterSignupPage /> },
    { path: 'unsubscribe-newsletter', element: <LazyUnsubscribePage /> },
    { path: 'support', element: <LazySupportPage /> },
    { path: 'resources', element: <LazyResourcesPage /> },

    // Landing Pages
    { path: 'join-community', element: <LazyLandingForumPage /> },
  ],
});

export default MainRoutes;
