import React, { Component } from 'react';
import { Button } from '@mui/material'; // if you're using Material-UI
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    // Send this to a logging server or service.
  }

  handleRetry = () => {
    // For now, just reload the page.
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '2rem', textAlign: 'center' }}>
          <h1>Oops! Something went wrong.</h1>
          <p>
            Please try refreshing the page or contact support if the problem
            persists.
          </p>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleRetry}
          >
            Retry
          </Button>
          <p>
            If you keep experiencing issues, you can:
            <ul>
              <li>
                <a
                  href="https://community.crushingsecurity.com/c/community-center/feedback-suggestions/51"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Leave feedback
                </a>
              </li>
              <li>
                <a href="/support">Contact support</a>
              </li>
            </ul>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
