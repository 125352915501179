import { Box } from '@mui/material';
import CustomButton from '../../../../../components/buttons/CustomButton';
const UnauthenticateHeader = () => {
  return (
    <Box
      sx={{
        gap: '15px',
        alignItems: 'center',
        display: 'flex',
        ml: 'auto', // Add this line
      }}
    >
      <CustomButton
        href="/login"
        variant="contained"
        color="secondary"
        padding="5px"
        size="md"
        width="100px"
        height="40px"
      >
        Sign In
      </CustomButton>
      <CustomButton
        href="/register"
        variant="contained"
        color="secondary"
        padding="5px"
        size="md"
        width="100px"
        height="40px"
      >
        Sign Up
      </CustomButton>
    </Box>
  );
};

export default UnauthenticateHeader;
