import { AxiosInstance } from '../../datafetching/axios';

const GetData = async (url) => {
  try {
    const response = await AxiosInstance.get(url);
    return { isSuccess: true, data: response.data, error: null };
  } catch (error) {
    let errorMessage = 'An error occurred';

    // The request was made and the server responded with a status code that falls out of the range of 2xx
    if (error.response) {
      errorMessage = `Server responded with status code ${
        error.response.status
      }: ${error.response.statusText}. Data: ${JSON.stringify(
        error.response.data,
      )}`;
    } else if (error.request) {
      // The request was made but no response was received
      errorMessage =
        'No response received from the server. Request: ' + error.request;
    } else {
      // Something happened in setting up the request that triggered an Error
      errorMessage = 'Request error: ' + error.message;
    }

    return { isSuccess: false, data: null, error: errorMessage };
  }
};

export default GetData;
