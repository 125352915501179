import React from 'react';
import {
  Facebook as FacebookIcon,
  YouTube as YouTubeIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
  Telegram as TelegramIcon,
} from '@mui/icons-material';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import {
  Box,
  Typography,
  List,
  Link,
  ListItem,
  IconButton,
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const social_media_links = [
  {
    icon: <FacebookIcon />,
    href: 'https://www.facebook.com/profile.php?id=100095562912385&amp;mibextid=ZbWKwL',
  },
  { icon: <TwitterIcon />, href: 'https://twitter.com/CrushingSecurit' },
  {
    icon: <YouTubeIcon />,
    href: 'https://www.youtube.com/channel/UCMCuixU31tL1-B-VTkW62cg',
  },
  {
    icon: <LinkedInIcon />,
    href: 'https://www.linkedin.com/company/crushing-security/',
  },
  {
    icon: <FontAwesomeIcon icon={faTiktok} />,
    href: 'https://www.tiktok.com/@crushingsecurity',
  },
  { icon: <TelegramIcon />, href: 'https://t.me/crushingsecurity' },
];

const footer_menu_columns = [
  {
    title: 'Site Navigation',
    items: [
      { name: 'Home', path: '/home' },
      { name: 'Get Started', path: '/welcome' },
      { name: 'Community', path: 'https://community.crushingsecurity.com/' },
      { name: 'Blogs', path: 'https://blogs.crushingsecurity.com/' },
      { name: 'About', path: '/about' },
      { name: 'Contact / Support', path: '/support' },
      {
        name: 'Feedback & Suggestions',
        path: 'https://community.crushingsecurity.com/c/community-center/feedback-suggestions/51',
      },
      { name: 'Connect & Engage', path: '/connect' },
    ],
  },
  {
    title: 'Community',
    items: [
      { name: 'Home', path: 'https://community.crushingsecurity.com' },
      { name: 'Latest', path: 'https://community.crushingsecurity.com/latest' },
      { name: 'Top', path: 'https://community.crushingsecurity.com/top' },
      {
        name: 'Community Center',
        path: 'https://community.crushingsecurity.com/c/community-center/45',
      },
      {
        name: 'Cybersecurity Fundamentals',
        path: 'https://community.crushingsecurity.com/c/cybersecurity-fundamentals/8',
      },
      {
        name: 'Advanced Security',
        path: 'https://community.crushingsecurity.com/c/advanced-cybersecurity-topics/15',
      },
      {
        name: 'Gold Threads',
        path: 'https://community.crushingsecurity.com/tag/gold',
      },
      {
        name: 'Notable Threads',
        path: 'https://community.crushingsecurity.com/tag/notable',
      },
    ],
  },
  {
    title: 'Resources',
    items: [
      {
        name: 'Resource Masterlist',
        path: 'https://community.crushingsecurity.com/t/comunity-resource-masterlist/90',
      },
    ],
  },
  {
    title: 'About',
    items: [
      { name: 'About Us', path: '/about' },
      {
        name: 'Steves Intro Post',
        path: 'https://community.crushingsecurity.com/t/greetings-from-steve-the-force-behind-crushing-security/89',
      },
      {
        name: 'Privacy',
        path: 'https://community.crushingsecurity.com/t/crushing-security-privacy-policy/29',
      },
      {
        name: 'Terms of Use',
        path: 'https://community.crushingsecurity.com/t/terms-and-rules/28',
      },
      {
        name: 'FAQ',
        path: 'https://community.crushingsecurity.com/t/frequently-asked-questions/15',
      },
    ],
  },
];
function FooterColumn({ title, items }) {
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <List>
        {items.map((item, index) => (
          <ListItem key={index}>
            <Link href={item.path} color="inherit" variant="body2">
              {item.name}
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

function Footer() {
  return (
    <Box sx={{ backgroundColor: 'grey.800', color: 'white', p: 5, mt: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Crushing Security
          </Typography>
          <Typography>
            Join Crushing Security for the latest in cybersecurity. Dive deep,
            get updates, and strengthen your digital defenses.
          </Typography>
        </Grid>
        {footer_menu_columns.map((column, index) => (
          <Grid item xs={12} md={2} key={index}>
            <FooterColumn title={column.title} items={column.items} />
          </Grid>
        ))}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Connect with us
          </Typography>
          {social_media_links.map((media, index) => (
            <IconButton
              color="inherit"
              href={media.href}
              target="_blank"
              key={index}
            >
              {media.icon}
            </IconButton>
          ))}
          {/* No need for individual IconButtons now */}
        </Grid>
      </Grid>
      <Box mt={5}>
        <Typography variant="body2" align="center">
          © {new Date().getFullYear()} Crushing Security. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
}

FooterColumn.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Footer;
