import { useContext } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import AuthContext from '../../../../../statemanagement/AuthContext';
import SearchBar from '../../../../components/SearchBar';
import Profile from './Profile';
import NavBarTitle from './NavBarTitle';
import Notification from './Notification';
import Message from './Message';
import UnauthenticateHeader from './Unauthenticated';

// This component represents the main content of the header.
const HeaderContent = () => {
  // Checking screen size to adjust the component layout.
  const matchesSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  // Fetching authentication state.
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <>
      {!matchesSm && <NavBarTitle />}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', ml: 'auto' }}>
        <SearchBar displayType="icon" />
        {isAuthenticated ? <Message /> : null}
        {isAuthenticated ? <Notification /> : null}
        {isAuthenticated ? <Profile /> : <UnauthenticateHeader />}
      </Box>
    </>
  );
};

export default HeaderContent;
