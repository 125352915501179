import { useRef, useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from '@mui/material';

// project import
import MainCard from '../../../../components/MainCard';
import Transitions from '../../../../components/@extended/Transitions';

// assets
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CloseOutlined from '@mui/icons-material/CloseOutlined';

import GetData from '../../../../../services/datafetching/GetData';
import requester from '../../../../../services/datasending/requester';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem',
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none',
};

// ==============================|| HEADER CONTENT - MESSAGE ||============================== //

const Message = () => {
  // Setup for component styling and behavior.
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  // State management for the component.
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);

  // Function to mark a message as read and redirect to the message URL.
  const handleMessageClick = async (messageId, url) => {
    const { isSuccess, error } = await requester(
      `/api/v1/messages/${messageId}/mark-as-read/`,
    );

    if (isSuccess) {
      // Update the state of the message to reflect that it has been read
      setMessages(
        messages.map((message) =>
          message.id === messageId ? { ...message, read: true } : message,
        ),
      );
      // Redirect the user to the message URL
      window.location.href = url;
    } else {
      console.error('Error marking message as read:', error);
    }
  };

  // Fetching the messages when the component mounts.
  useEffect(() => {
    GetData('/api/v1/messages/').then(({ isSuccess, data, error }) => {
      if (isSuccess) {
        setMessages(data);
      } else {
        console.error('Error fetching messages:', error);
      }
    });
  }, []);

  // Toggle function to show/hide the message dropdown and mark all messages as viewed.
  const handleToggle = async () => {
    if (!open) {
      const { isSuccess, error } = await requester(
        '/api/v1/messages/mark-all-viewed/',
      );

      if (isSuccess) {
        // Update the state of the messages to reflect that they have been viewed
        setMessages(messages.map((message) => ({ ...message, viewed: true })));
      } else {
        console.error('Error marking all messages as viewed:', error);
      }
    }

    setOpen((prevOpen) => !prevOpen);
  };
  // Close function for the dropdown.
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  //const iconBackColorOpen = blue[900];
  //const iconBackColor = blue[600];

  return (
    <Box sx={{ flexShrink: 0, ml: 'auto' }}>
      <IconButton
        sx={{
          color: 'text.primary',
          //bgcolor: open ? iconBackColorOpen : iconBackColor,
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge
          badgeContent={messages.filter((message) => !message.viewed).length}
          color="error"
        >
          <MailOutlineIcon />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Message"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <IconButton size="small" onClick={handleToggle}>
                      <CloseOutlined />
                    </IconButton>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': {
                          ...actionSX,
                          position: 'relative',
                        },
                      },
                    }}
                  >
                    {messages.map((message) => (
                      <ListItemButton
                        key={message.id}
                        onClick={() =>
                          handleMessageClick(message.id, message.url)
                        }
                        style={{
                          backgroundColor: message.read
                            ? 'transparent'
                            : '#f0f0f0',
                          fontWeight: message.read ? 'normal' : 'bold',
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                            }}
                          >
                            <MailOutlineIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="h6">
                              {message.title}
                            </Typography>
                          }
                          secondary={message.message.substring(0, 100)}
                        />
                        <ListItemSecondaryAction>
                          <Typography variant="caption" noWrap>
                            {new Date(message.created_at).toLocaleString()}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    ))}
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Message;
