import { AxiosInstance } from '../../datafetching/axios';

let csrfToken = null;

const PostFormData = async (url, form_data) => {
  try {
    const response = await AxiosInstance.post(url, form_data, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });
    if (response.data.csrfmiddlewaretoken) {
      csrfToken = response.data.csrfmiddlewaretoken;
    }
    return { isSuccess: true, response: response.data, error: '' };
  } catch (error) {
    let errorMessage = 'An error occurred';

    // The request was made and the server responded with a status code that falls out of the range of 2xx
    if (error.response) {
      errorMessage = error.response.statusText;
      if (errorMessage === 'Unauthorized') {
        return { isSuccess: false, response: error, error: errorMessage };
      }
    } else if (error.request) {
      // The request was made but no response was received
      errorMessage = 'No response received from the server';
    } else {
      // Something happened in setting up the request that triggered an Error
      errorMessage = error.message;
    }

    return { isSuccess: false, response: error, error: errorMessage };
  }
};

export default PostFormData;
