import { useContext } from 'react';
import { useRoutes } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

import AuthContext from '../../statemanagement/AuthContext';
// ==============================|| ROUTING RENDER ||============================== //

export default function Routes() {
  let { isAuthenticated } = useContext(AuthContext);

  return useRoutes([MainRoutes({ isAuthenticated }), AuthenticationRoutes]);
}
