import * as React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

function CustomButton(props) {
  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
  const {
    type,
    color,
    variant,
    size,
    href,
    children,
    padding,
    width,
    height,
    onClick,
    loading,
    startIcon,

    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    sx,
  } = props;

  return (
    <Button
      type={type}
      color={color}
      variant={variant}
      size={size}
      href={href}
      onClick={onClick}
      startIcon={startIcon}
      disabled={loading}
      sx={{
        ...sx,
        fontSize: 18,
        fontWeight: 'bold',
        cursor: 'pointer',
        width: width || 'auto',
        height: height || 'auto',
        padding: padding || '12px 24px',

        marginTop: marginTop || '0px',
        marginBottom: marginBottom || '0px',
        marginLeft: marginLeft || '0px',
        marginRight: marginRight || '0px',

        backgroundColor: theme.palette[color].main,
        '&:hover': {
          backgroundColor: theme.palette[color].dark,
          transform: 'scale(1.05)', // Slightly reduced the scale for subtleness
          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)', // Added shadow
          '& .MuiButton-startIcon': {
            // Targeting the startIcon
            transform: matchesLg
              ? 'scale(1.1) rotate(15deg)'
              : 'scale(1.2) rotate(20deg)', // Adjust based on screen size
          },
        },
        '&:focus': {
          outline: `3px solid ${theme.palette[color].light}`,
        },
        '&:active': {
          backgroundColor: theme.palette[color].light,
        },
        borderRadius: '12px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
      }}
    >
      {loading ? <CircularProgress size={24} /> : children}
    </Button>
  );
}

CustomButton.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node,
  padding: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  startIcon: PropTypes.node,
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sx: PropTypes.object,
};

CustomButton.defaultProps = {
  type: 'button',
  color: 'primary',
  variant: 'contained',
  padding: '0',
  size: 'medium',
  width: 'auto',
  height: 'auto',
  loading: false,
  marginTop: '0px',
  marginBottom: '0px',
  marginLeft: '0px',
  marginRight: '0px',
  onClick: () => {},
  sx: {},
};

export default CustomButton;
