// External dependencies imports
import React, { createContext, useState, useEffect, forwardRef } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Services imports
import authService from '../services/authentication/auth-service';
import userService from '../services/datafetching/user-service';
import GetData from '../services/datafetching/GetData';

const AuthContext = createContext();
export default AuthContext;

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AuthProvider = ({ children }) => {
  // State
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isUserProfileLoading, setIsUserProfileLoading] = useState(false);
  const [isAuthenticationCheckLoading, setIsAuthenticationCheckLoading] =
    useState(false);
  const [user, setUser] = useState(null);
  const [loginSnackBarOpen, setLoginRedirectNotification] = useState(false);
  const [navigateOnLogout, setNavigateOnLogout] = useState('');
  const [timer, setTimer] = useState(null);
  const navigate = useNavigate();

  const [hasCommunityProfile, setHasCommunityProfile] = useState(true);

  // Effects
  useEffect(() => {
    if (loginSnackBarOpen) {
      setTimer(
        setTimeout(() => {
          //setLoginSnackbar(false);
        }, 3000),
      );
    }
    return () => {
      clearTimeout(timer);
    };
  }, [loginSnackBarOpen]);

  // Handlers
  const handleClose = () => {
    setLoginRedirectNotification(false);
    if (navigateOnLogout) {
      navigate(navigateOnLogout);
    }
  };

  const setUserInfo = (newUser) => {
    setUser((currentUser) => {
      // Only update the state if the user data has changedge
      if (JSON.stringify(currentUser) !== JSON.stringify(newUser)) {
        return newUser;
      }
      return currentUser;
    });
  };

  useEffect(() => {
    if (!user) {
      setUserInfo({
        about: '',
        avatar_url: '',
        timezone: '',
        email: '',
        name: '',
        is_email_verified: false,
        start_date: '',
        user_id: '',
        username: '',
      });
    }
  }, [user]);

  useEffect(() => {
    checkAuthenticated();
  }, [user]);

  useEffect(() => {
    (async () => {
      const exists = await checkCommunityProfileExists();
      if (exists !== null) {
        setHasCommunityProfile(exists);
      }
    })();
  }, [user]);

  const setAvatarURL = async (newAvatarURL) => {
    try {
      const response = await userService.updateAvatarURL(newAvatarURL);
      if (response.status === 200 || response.status === 201) {
        setUserInfo((currentUser) => ({
          ...currentUser,
          avatar_url: newAvatarURL,
        }));
      } else {
        //console.log('AuthContext: Could not update avatar URL');
      }
    } catch (err) {
      //console.error(err);
    }
  };

  async function checkAuthenticated() {
    setIsAuthenticationCheckLoading(true);
    try {
      await authService.checkAuthenticated().then((response) => {
        if (response.status === 200 || response.status === 201) {
          setIsAuthenticated(true);
        } else if (response.status === 401 || response.status === 403) {
          if (isAuthenticated) {
            if (response.token === 'expired_or_error') {
              setIsAuthenticated(false);
              clearSession('login');
            }
            setIsAuthenticated(false);
          }
        } else {
          setIsAuthenticated(false);
        }
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsAuthenticationCheckLoading(false);
    }
  }

  async function getUserProfileInfo() {
    setIsUserProfileLoading(true);
    try {
      // Call the /update-avatar endpoint to check if the avatar has changed
      await userService.updateAvatarURL();

      const response = await GetData('/profile/get-profile-info/');
      if (response.isSuccess) {
        setUserInfo({
          about: response.data.about,
          avatar_url: response.data.avatar_url,
          //timezone: response.data.timezone,
          email: response.data.email,
          name: response.data.name,
          is_email_verified: response.data.is_email_verified,
          start_date: response.data.start_date,
          user_id: response.data.user_id,
          username: response.data.username,
        });
      } else {
        //console.log('Could not get user profile info');
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsUserProfileLoading(false);
    }
  }

  let updateSession = async () => {
    checkAuthenticated();
  };

  const checkCommunityProfileExists = async () => {
    if (user && user.username) {
      const result = await GetData(
        `/discourse/check_profile_exists/${user.user_id}`,
      );
      if (result.isSuccess) {
        return result.data.exists;
      } else {
        // console.error('Failed to check community profile');
        return null;
      }
    }
    return null;
  };

  const clearSession = (navigateTo = '', showNotification = true) => {
    authService
      .logout()
      .then((response) => {
        if (response.status !== 200 || response.status !== 201) {
          // console.error('Could not logout user');
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsAuthenticated(false);
        setUserInfo({
          about: '',
          avatar_url: '',
          timezone: '',
          email: '',
          name: '',
          is_email_verified: false,
          start_date: '',
          user_id: '',
          username: '',
        });

        if (navigateTo) {
          setNavigateOnLogout(navigateTo);
          if (showNotification) {
            setLoginRedirectNotification(true);
          }
        }
      });
  };

  let contextData = {
    user,
    isAuthenticationCheckLoading,
    isUserProfileLoading,
    isAuthenticated,
    clearSession,
    updateSession,
    getUserProfileInfo,
    setAvatarURL,
    hasCommunityProfile,
  };

  return (
    <>
      <AuthContext.Provider value={contextData}>
        {children}
      </AuthContext.Provider>
      <Snackbar
        open={loginSnackBarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          Your session has expired. Please login again.
        </Alert>
      </Snackbar>
    </>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
