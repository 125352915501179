import axios from 'axios';
import authService from '../services/authentication/auth-service.js';

const baseURL =
  process.env.REACT_APP_API_URL || 'https://api.crushingsecurity.com';

const apiV1 = '/api/v1/';

//const statusCode200 = 200;
//const statusCode201 = 201;
const statusCode403 = 403;
const statusCode401 = 401;

let headers = {
  //'Content-Type': 'application/json',
  //accept: 'application/json',
};

let headers_POST = {
  'Content-Type': 'application/json',
  //accept: 'application/json',
};

const DiscourseAxiosInstance = axios.create({
  baseURL: 'https://community.crushingsecurity.com', // Base URL for Discourse API
  timeout: 20000,
  headers: headers,
  withCredentials: true,
});

const AxiosInstance_FORM = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  headers: headers_POST,
  withCredentials: true,
});

const AxiosInstance_API = axios.create({
  baseURL: baseURL + apiV1,
  timeout: 20000,
  headers: headers,
  withCredentials: true,
});

const AxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: headers,
  withCredentials: true,
});

const AxiosInstanceNoInterceptor = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: headers,
  withCredentials: true,
});

AxiosInstance.interceptors.request.use(
  async (config) => {
    config.headers['X-CSRFToken'] = localStorage.getItem('csrfmiddlewaretoken');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

AxiosInstanceNoInterceptor.interceptors.request.use(
  async (config) => {
    config.headers['X-CSRFToken'] = localStorage.getItem('csrfmiddlewaretoken');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

AxiosInstance.interceptors.response.use(
  (response) => response,
  async (authCheckResponse) => {
    const originalRequest = authCheckResponse.config;
    const { status } = authCheckResponse.response;

    if (
      (status === statusCode403 && !originalRequest._retry) ||
      (status === statusCode401 && !originalRequest._retry)
    ) {
      originalRequest._retry = true;
      try {
        const refreshResponse = await authService.refreshToken();
        if (refreshResponse.token === 'valid') {
          return axios(originalRequest);
        } else {
          return refreshResponse;
        }
      } catch (err) {
        //console.error('axios.interceptors' + err);
        return Promise.reject(authCheckResponse);
      }
    }
    //console.error('axios.interceptors REJECT');
    return Promise.reject(authCheckResponse);
  },
);

/*
Access token lifespan is usually short. Axios Interceptor can be used to intercept requests and see if tokens are valid. 
If not, we will request for a new token.

Axios library has interceptors which will run first prior to the actual request. 
*/

AxiosInstance_API.interceptors.response.use(
  (response) => response,
  async (authCheckResponse) => {
    const originalRequest = authCheckResponse.config;
    const { status } = authCheckResponse.response;

    if (
      (status === statusCode403 && !originalRequest._retry) ||
      (status === statusCode401 && !originalRequest._retry)
    ) {
      originalRequest._retry = true;
      try {
        const refreshResponse = await authService.refreshToken();
        if (refreshResponse.token === 'valid') {
          return axios(originalRequest);
        } else {
          return refreshResponse;
        }
      } catch (err) {
        //console.error('axios.interceptors' + err);
        return Promise.reject(authCheckResponse);
      }
    }
    // console.error('axios.interceptors REJECT');
    return Promise.reject(authCheckResponse);
  },
);

axios.interceptors.response.use(
  (response) => response,
  async (authCheckResponse) => {
    const originalRequest = authCheckResponse.config;
    const { status } = authCheckResponse.response;

    if (
      (status === statusCode403 && !originalRequest._retry) ||
      (status === statusCode401 && !originalRequest._retry)
    ) {
      originalRequest._retry = true;
      try {
        const refreshResponse = await authService.refreshToken();
        if (refreshResponse.token === 'valid') {
          return axios(originalRequest);
        } else {
          return refreshResponse;
        }
      } catch (err) {
        //  console.error('axios.interceptors' + err);
        return Promise.reject(authCheckResponse);
      }
    }
    //console.error('axios.interceptors REJECT');
    return Promise.reject(authCheckResponse);
  },
);
export {
  AxiosInstance,
  AxiosInstanceNoInterceptor,
  AxiosInstance_API,
  AxiosInstance_FORM,
  DiscourseAxiosInstance,
};
