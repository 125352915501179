import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import { red, grey, green, yellow } from '@mui/material/colors';

const redTeamTheme = createTheme(baseTheme, {
  palette: {
    mode: 'dark',
    primary: {
      main: red[500],
    },
    secondary: {
      main: red[200],
    },
    background: {
      default: grey[900],
      paper: grey[800],
    },
    text: {
      primary: grey[300],
      secondary: grey[500],
    },
    success: {
      main: green[500],
    },
    info: {
      main: red[400],
    },
    warning: {
      main: yellow[700],
    },
    error: {
      main: red[500],
    },
  },
});

export default redTeamTheme;
