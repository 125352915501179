import { AppBar, Box, Toolbar } from '@mui/material';
import HeaderContent from './HeaderContent';
import Drawer from './HeaderContent/Drawer';

const Header = () => {
  const mainHeader = (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          <Drawer />
          <HeaderContent />
        </Toolbar>
      </AppBar>
    </Box>
  );

  return <>{mainHeader}</>;
};

export default Header;
