import { AxiosInstance } from '../../datafetching/axios';

const PROFILE_URL = '/profile';

const getUserNotifications = () => {};

const getUserMessages = () => {};

const getAllUserInfo = () => {};

const getUserProfileInfo = () => {
  console.log('getting user info');
  return AxiosInstance.get(PROFILE_URL + '/get-profile-info/')
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('GetUserProfileInfo ERROR');
      console.log(error);
      return error;
    });
};

const updateUserProfileInfo = (user) => {
  console.log('updating user info');
  return AxiosInstance.post(PROFILE_URL + '/update-user/', {
    email: user.email,
    username: user.username,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('update user profile ERROR');
      console.log(error);
      return error;
    });
};

const getAvatarURL = () => {
  return AxiosInstance.get(PROFILE_URL + '/get-avatar/')
    .then((res) => res)
    .catch((error) => {
      console.log(PROFILE_URL + 'GetAvatarURL ERROR');
      console.log(error);
      return error;
    });
};

const updateAvatarURL = () => {
  return AxiosInstance.get(PROFILE_URL + '/update-avatar/')
    .then((res) => res)
    .catch((error) => {
      console.log(PROFILE_URL + 'UpdateAvatarURL ERROR');
      console.log(error);
      return error;
    });
};

const userService = {
  getUserNotifications,
  getUserMessages,
  getUserProfileInfo,
  getAllUserInfo,
  updateUserProfileInfo,
  getAvatarURL,
  updateAvatarURL,
};

export default userService;
