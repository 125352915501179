import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import { deepPurple, teal, grey, lightGreen, red } from '@mui/material/colors';

const darkTheme = createTheme(baseTheme, {
  palette: {
    type: 'dark',
    primary: {
      main: deepPurple[400],
    },
    secondary: {
      main: teal[500],
    },
    background: {
      default: grey[900],
      paper: grey[800],
    },
    text: {
      primary: '#FFFFFF',
      secondary: grey[300],
    },
    success: {
      main: lightGreen[500],
    },
    info: {
      main: '#2196f3',
    },
    warning: {
      main: '#ff9800',
    },
    error: {
      main: red[500],
    },
  },
});

export default darkTheme;
