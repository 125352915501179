import {
  AxiosInstance,
  AxiosInstanceNoInterceptor,
} from '../../datafetching/axios';

const API__AUTH_URL = 'auth';

const checkAuthenticated = () => {
  return AxiosInstance.get(API__AUTH_URL + '/check-loggedin/')
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

const refreshToken = () => {
  return AxiosInstanceNoInterceptor.post(API__AUTH_URL + '/token/refresh/')
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return { ...res, token: 'valid' };
      } else {
        return { ...res, token: 'expired_or_error' };
      }
    })
    .catch((error) => {
      console.error(error);
      return { ...error, token: 'expired_or_error' };
    });
};

/*
Register a user, initialises the access_token and refresh_token variables. They will be 'unefined' until a user logs in

* Access Tokens and Refresh Tokens are at risk to XSS
* Recommended for Access Tokens to only be stored in memmory so that they will be automatically lost when the app is closed. 
    They should not be stored in localstorage or in a cookie.

    API issue refresh tokens in an httponly cookie which is not accessible via JavaScript. Must have an expiry point at some point. NEVER unlimited access. 

    API verfies the Access Token with middleware on every request. When expired the Refresh token will be used to request a new Access Token. 
*/
const signup = (
  email,
  username,
  password,
  password_confirmation,
  subscribeUpdates,
  subscribeNewsletter,
) => {
  return AxiosInstance.post(API__AUTH_URL + '/register/', {
    email,
    username,
    password,
    password_confirmation,
    subscribeUpdates,
    subscribeNewsletter,
  })
    .then((res) => {
      return {
        isSuccess: res.data.isSuccess,
        user: res.data.user,
        error_types: [],
      };
    })
    .catch(function (error) {
      console.log(error);
      if (error.response) {
        var error_types = error.response.data.error_types || []; // Access error_types directly
        return { isSuccess: false, error_types: error_types };
      } else if (error.request) {
        console.log(error.request);
        return {
          isSuccess: false,
          error_types: ['error-authenticating-to-server'],
        };
      } else {
        console.log('Error', error.message);
        return {
          isSuccess: false,
          error_types: ['error-authenticating-to-server'],
        };
      }
    });
};

const login = (email, password, ssoPayload, sig) => {
  const requestData = {
    username: email,
    password: password,
  };

  // If SSO payload and signature are present, include them in the request data
  if (ssoPayload && sig) {
    requestData['sso'] = ssoPayload;
    requestData['sig'] = sig;
  }

  let endpoint = 'auth/login/';

  return AxiosInstance.post(endpoint, requestData)
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        // Store the CSRF token
        localStorage.setItem(
          'csrfmiddlewaretoken',
          res.data.csrfmiddlewaretoken,
        );
        return res;
      }
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.statusText === 'Unauthorized') {
          return {
            isSuccess: false,
            response: error,
            error: error.response.statusText,
          };
        }
      } else if (error.request) {
        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser.
        //var status_code = error.request.status
      }
      return { isSuccess: false, response: error, error: 'error' };
    });
};

const logout = () => {
  return AxiosInstance.post(API__AUTH_URL + '/logout/', {})
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        //
      } else {
        //
      }

      //return { 'isSuccess': true, 'response': res, 'error': '' }
    })
    .catch(function (error) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      if (error.response) {
        if (error.response.statusText === 'Unauthorized') {
          return {
            isSuccess: false,
            response: error,
            error: error.response.statusText,
          };
        }
      } else if (error.request) {
        //
      }
      return { isSuccess: false, response: error, error: 'error' };
    });
};
const ssologin = (email, password, ssoPayload) => {
  return AxiosInstance.post(API__AUTH_URL + '/sso/', {
    email,
    password,
    ssoPayload,
  })
    .then((res) => {
      if (res.status === 200) {
        window.location.assign(res.data.redirect_url);
      } else if (res.status === 401) {
        // Show an error message to the user
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

const authService = {
  signup,
  login,
  checkAuthenticated,
  ssologin,
  logout,
  refreshToken,
};

export default authService;
