import React from 'react';
import { Link } from '@mui/material';

// This component serves as the title for the navigation bar.
const NavBarTitle = () => {
  return (
    // Using the Link component to make the title clickable.
    // The title redirects to the root ('/') when clicked.
    <Link
      variant="h6"
      underline="none"
      color="inherit"
      href="/"
      sx={{
        align: 'left',
        mr: 2,
        display: { xs: 'none', md: 'block' },
        fontSize: 20,
      }}
    >
      {'Crushing Security'}
    </Link>
  );
};

export default NavBarTitle;
