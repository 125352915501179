// material-ui
import { useTheme } from '@mui/material/styles';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

// assets
import CommentOutlined from '@mui/icons-material/CommentOutlined';
import LockOutlined from '@mui/icons-material/LockOutlined';
import QuestionCircleOutlined from '@mui/icons-material/HelpOutlineOutlined';
import UserOutlined from '@mui/icons-material/PersonOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const SettingTab = ({ handleClose }) => {
  const theme = useTheme();

  const navigate = useNavigate();
  return (
    <List
      component="nav"
      sx={{
        p: 0,
        '& .MuiListItemIcon-root': {
          minWidth: 32,
          color: theme.palette.text.secondary,
        },
      }}
    >
      <ListItemButton
        onClick={() => {
          handleClose();
          navigate('/support');
        }}
      >
        <ListItemIcon>
          <QuestionCircleOutlined />
        </ListItemIcon>
        <ListItemText primary="Support" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          handleClose();
          navigate('/profile');
        }}
      >
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="Account Settings" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          handleClose();
          navigate('/privacy');
        }}
      >
        <ListItemIcon>
          <LockOutlined />
        </ListItemIcon>
        <ListItemText primary="Privacy Center" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          handleClose();
          window.location.assign(
            'https://community.crushingsecurity.com/c/community-center/feedback-suggestions/51',
          );
        }}
      >
        <ListItemIcon>
          <CommentOutlined />
        </ListItemIcon>
        <ListItemText primary="Feedback" />
      </ListItemButton>
    </List>
  );
};

SettingTab.propTypes = {
  handleClose: PropTypes.func.isRequired, // Validate the handleClose prop
};

export default SettingTab;
