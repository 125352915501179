import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import PersonOutline from '@mui/icons-material/PersonOutline';

// Import the AuthContext
import AuthContext from '../../../../../../statemanagement/AuthContext';

const ProfileTab = ({ handleLogout }) => {
  const theme = useTheme();

  // Get the user and hasCommunityProfile from the AuthContext
  const { user, hasCommunityProfile } = useContext(AuthContext);

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        '& .MuiListItemIcon-root': {
          minWidth: 32,
          color: theme.palette.text.secondary,
        },
      }}
    >
      <ListItemButton
        onClick={() => {
          // Use the user's username for the URL
          if (hasCommunityProfile) {
            window.location.assign(
              `http://community.crushingsecurity.com/u/${user.username}/`,
            );
          } else {
            window.location.assign(
              `http://community.crushingsecurity.com/login`,
            );
          }
        }}
      >
        <ListItemIcon>
          <PersonOutline />
        </ListItemIcon>
        <ListItemText primary="Social Profile" />
      </ListItemButton>
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
};

export default ProfileTab;
