import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import { blue, indigo, grey, cyan, red, green } from '@mui/material/colors';

const blueTeamTheme = createTheme(baseTheme, {
  palette: {
    mode: 'dark',
    primary: {
      main: blue[700], // A darker shade for primary
    },
    secondary: {
      main: cyan[500], // A contrasting secondary color
    },
    background: {
      default: grey[900], // Dark background for a dark theme
      paper: indigo[800], // Deep indigo for paper elements
    },
    text: {
      primary: grey[300], // Light text for better readability on dark background
      secondary: grey[500], // Slightly darker grey for secondary text
    },
    success: {
      main: green[500],
    },
    info: {
      main: blue[500],
    },
    warning: {
      main: '#ff9800',
    },
    error: {
      main: red[500],
    },
  },
});

export default blueTeamTheme;
