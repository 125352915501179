import React from 'react';
import {
  Divider,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

// MUI Imports
import { grey } from '@mui/material/colors';
import Stack from '@mui/material/Stack';

// Icons
import {
  Facebook as FacebookIcon,
  YouTube as YouTubeIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
  Telegram as TelegramIcon,
} from '@mui/icons-material';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AnnouncementIcon from '@mui/icons-material/Announcement';
import AppsIcon from '@mui/icons-material/Apps';
import ArticleIcon from '@mui/icons-material/Article';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BugReportIcon from '@mui/icons-material/BugReport';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
//import FlagIcon from '@mui/icons-material/Flag';
import FeedbackIcon from '@mui/icons-material/Feedback';
import GiftIcon from '@mui/icons-material/CardGiftcard';

import ForumIcon from '@mui/icons-material/Forum';
import LockIcon from '@mui/icons-material/Lock';
//import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PeopleIcon from '@mui/icons-material/People';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircle';

import PublicIcon from '@mui/icons-material/Public';
import SecurityIcon from '@mui/icons-material/Security';
import StarIcon from '@mui/icons-material/Star';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
//import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
//import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

// Hooks and Context
import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Local Imports
import lightTheme from '../../../../../styling/themes/lightTheme';
import darkTheme from '../../../../../styling/themes/darkTheme';
import blueTeamTheme from '../../../../../styling/themes/blueTeamTheme';
import redTeamTheme from '../../../../../styling/themes/redTeamTheme';
import purpleTeamTheme from '../../../../../styling/themes/purpleTeamTheme';
import { ThemeContext } from '../../../../../styling/themes/themeContext';

import PropTypes from 'prop-types';

var drawerWidth = 365;
const drawer_sections = [
  {
    name: 'Home',
    child_pages: [
      {
        name: 'My Home',
        icon: <HomeIcon />,
        path: '/',
        child_pages: [],
      },
    ],
  },
  {
    name: 'Community',
    child_pages: [
      {
        name: 'Home',
        path: 'https://community.crushingsecurity.com/',
        icon: <PeopleIcon />,
        child_pages: [],
      },
      {
        name: 'Forums',
        icon: <ForumIcon />,
        path: '',
        child_pages: [
          {
            name: 'Home',
            icon: <HomeIcon />,
            path: 'https://community.crushingsecurity.com',
            child_pages: [],
          },
          {
            name: 'Latest',
            icon: <NewReleasesIcon />,
            path: 'https://community.crushingsecurity.com/latest',
            child_pages: [],
          },
          {
            name: 'Top',
            icon: <StarIcon />,
            path: 'https://community.crushingsecurity.com/top',
            child_pages: [],
          },
          {
            name: 'Resources',
            icon: <PlayCircleOutlinedIcon />,
            path: 'resources',
            child_pages: [],
          },
          {
            name: 'Community Center',
            icon: <PeopleIcon />,
            path: 'https://community.crushingsecurity.com/c/community-center/45',
            child_pages: [],
          },
          {
            name: 'Cybersecurity Fundamentals',
            icon: <SecurityIcon />,
            path: 'https://community.crushingsecurity.com/c/cybersecurity-fundamentals/8',
            child_pages: [],
          },
          {
            name: 'Advanced Security',
            icon: <LockIcon />,
            path: 'https://community.crushingsecurity.com/c/advanced-cybersecurity-topics/15',
            child_pages: [],
          },
          {
            name: 'Gold Threads',
            icon: <EmojiEventsIcon />,
            path: 'https://community.crushingsecurity.com/tag/gold',
            child_pages: [],
          },
          {
            name: 'Notable Threads',
            icon: <BookmarkIcon />,
            path: 'https://community.crushingsecurity.com/tag/notable',
            child_pages: [],
          },
        ],
      },
    ],
  },
  {
    name: 'Apps',
    child_pages: [
      {
        name: 'Home',
        icon: <AppsIcon />,
        path: '/apps',
        child_pages: [],
      },
    ],
  },
  {
    name: 'Resources',
    child_pages: [
      {
        name: 'Blogs / News/ Videos',
        icon: <ContentCopyIcon />,
        path: '',
        child_pages: [
          {
            name: 'Blogs',
            icon: <ArticleIcon />,
            path: 'https://blogs.crushingsecurity.com',
            child_pages: [],
          },
          {
            name: 'Community Blogs',
            icon: <PeopleIcon />,
            path: 'https://community.crushingsecurity.com/tag/blog',
            child_pages: [],
          },
          {
            name: 'Community Content',
            icon: <NewReleasesIcon />,
            path: 'https://community.crushingsecurity.com/c/community-center/45',
            child_pages: [],
          },
          {
            name: 'Community News/Updates',
            icon: <AnnouncementIcon />,
            path: 'https://community.crushingsecurity.com/c/community-center/community-news-updates/48',
            child_pages: [],
          },
          {
            name: 'Social Media',
            icon: <PublicIcon />,
            path: '',
            child_pages: [
              {
                name: 'Youtube',
                icon: <YouTubeIcon />,
                path: 'https://www.youtube.com/channel/UCMCuixU31tL1-B-VTkW62cg',
                child_pages: [],
              },
              {
                name: 'Twitter',
                icon: <TwitterIcon />,
                path: 'https://twitter.com/CrushingSecurit',
                child_pages: [],
              },
              {
                name: 'Telegram Crushing Security',
                icon: <TelegramIcon />,
                path: 'https://t.me/crushingsecurity',
                child_pages: [],
              },
              {
                name: 'Facebook',
                icon: <FacebookIcon />,
                path: 'https://www.facebook.com/profile.php?id=100095562912385&amp;mibextid=ZbWKwL',
                child_pages: [],
              },
              {
                name: 'LinkedIn',
                icon: <LinkedInIcon />,
                path: 'https://www.linkedin.com/company/crushing-security/',
                child_pages: [],
              },
              {
                name: 'TikTok',
                icon: <FontAwesomeIcon icon={faTiktok} />,
                path: 'https://www.tiktok.com/@crushingsecurity',
                child_pages: [],
              },
            ],
          },
        ],
      },
      {
        name: 'Resources',
        icon: <PlayCircleOutlinedIcon />,
        path: 'resources',
        child_pages: [],
      },
    ],
  },
  {
    name: 'About',
    child_pages: [
      {
        name: 'About',
        icon: <InfoIcon />,
        path: '/about',
        child_pages: [],
      },
      {
        name: 'Steves Intro',
        icon: <PersonPinIcon />,
        path: 'https://community.crushingsecurity.com/t/greetings-from-steve-the-force-behind-crushing-security/89',
        child_pages: [],
      },
    ],
  },
  {
    name: 'Help & Support',
    child_pages: [
      {
        name: 'Customer Service',
        icon: <SupportAgentIcon />,
        path: '/support',
        child_pages: [],
      },
      {
        name: 'Feedback & Suggestions',
        icon: <FeedbackIcon />,
        path: 'https://community.crushingsecurity.com/c/community-center/feedback-suggestions/51',
        child_pages: [],
      },
    ],
  },
  {
    name: 'Support Our Mission',
    child_pages: [
      {
        name: 'Support', // New section
        icon: <GiftIcon />, // You can choose a different icon if you prefer
        path: 'https://support.crushingsecurity.com/', // Link to your support page
        child_pages: [],
      },
    ],
  },
  {
    name: 'Theme',
    child_pages: [
      {
        name: 'Light',
        icon: <Brightness7Icon />,
        path: 'light',
        colorPreview: lightTheme.palette.primary.main,
        colorPreviewSecondary: lightTheme.palette.secondary.main,
        child_pages: [],
      },
      {
        name: 'Dark',
        icon: <Brightness2Icon />,
        path: 'dark',
        colorPreview: darkTheme.palette.primary.main,
        colorPreviewSecondary: darkTheme.palette.secondary.main,
        child_pages: [],
      },
      {
        name: 'Blue Team',
        icon: <SecurityIcon />,
        path: 'blueTeam',
        colorPreview: blueTeamTheme.palette.primary.main,
        colorPreviewSecondary: blueTeamTheme.palette.secondary.main,
        child_pages: [],
      },
      {
        name: 'Red Team',
        icon: <BugReportIcon />,
        path: 'redTeam',
        colorPreview: redTeamTheme.palette.primary.main,
        colorPreviewSecondary: redTeamTheme.palette.secondary.main,
        child_pages: [],
      },
      {
        name: 'Purple Team',
        icon: <MergeTypeIcon />,
        path: 'purpleTeam',
        colorPreview: purpleTeamTheme.palette.primary.main,
        colorPreviewSecondary: purpleTeamTheme.palette.secondary.main,
        child_pages: [],
      },
    ],
  },
];

const DrawerHeader = ({ onClick, header_title }) => {
  return (
    <Box
      padding={2}
      width={drawerWidth}
      textAlign="center"
      role="presentation"
      color="primary"
      sx={{
        backgroundColor: grey[300],
      }}
    >
      <Stack direction="row" justifyContent="end" spacing={4}>
        <Typography
          varient="h6"
          component="div"
          sx={{
            fontWeight: 'bold',
            fontSize: '25px',
            maxWidth: '90%',
            whiteSpace: 'nowrap',
            lineHeight: '25px',
            color: 'black',
            marginRight: '20px',
          }}
        >
          {header_title}
        </Typography>
        <CloseIcon
          onClick={onClick}
          //justifyContent="end"
          sx={{
            fontSize: 40,
            color: 'black',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: grey[200],
            },
          }}
        />
      </Stack>
    </Box>
  );
};

const NavigateRightIcon = ({ display }) => {
  return (
    <Box
      sx={{
        display: display,
      }}
    >
      <IconButton>
        <ChevronRightIcon
          sx={{
            width: 40,
            height: 40,
          }}
        />
      </IconButton>
    </Box>
  );
};

const NavigateLeftIcon = ({ display }) => {
  return (
    <Box
      sx={{
        display: display,
      }}
    >
      <IconButton>
        <ChevronLeftIcon
          sx={{
            width: 40,
            height: 40,
          }}
        />
      </IconButton>
    </Box>
  );
};

const MenuSectionHeader = ({ header_title }) => {
  return (
    <ListItem key={header_title} disablePadding>
      <ListItemText
        primary={header_title}
        disableTypography
        sx={{
          color: 'black',
          paddingRight: '20px',
          paddingLeft: '36px',
          paddingTop: '13px',
          fontWeight: 'bold',
        }}
      />
    </ListItem>
  );
};

const MenuReturnHeader = ({
  header_title,
  parentPageNavigationID,
  updateDrawer,
}) => {
  return (
    <div>
      <ListItem
        key={header_title}
        disablePadding
        onClick={() => updateDrawer(parentPageNavigationID)}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: grey[200],
          },
        }}
      >
        <NavigateLeftIcon display="block" />
        <Typography
          varient="h6"
          component="div"
          sx={{
            fontWeight: 'bold',
            fontSize: '18px',
            maxWidth: '90%',
            whiteSpace: 'nowrap',
            lineHeight: '25px',
            color: grey[800],
            marginRight: '20px',
          }}
        >
          {header_title}
        </Typography>
      </ListItem>
    </div>
  );
};

const MenuSectionItems = ({
  section_items,
  updateDrawer,
  parentName,
  handleThemeChange,
}) => {
  const theme = useTheme();

  return (
    <div>
      {section_items.map((child_item) => (
        <ListItem
          key={child_item.name}
          disablePadding
          onClick={() => {
            if (child_item.path.startsWith('http')) {
              window.location.assign(child_item.path);
            } else if (parentName === 'Theme') {
              handleThemeChange(child_item.path);
            } else {
              updateDrawer(child_item);
            }
          }}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.palette.background.default,
            },
          }}
        >
          {child_item.icon ? child_item.icon : null}

          <ListItemText
            primary={child_item.name}
            button={true}
            sx={{
              color: theme.palette.text.primary,
              paddingRight: theme.spacing(2.5),
              paddingLeft: theme.spacing(4.5),
              paddingTop: theme.spacing(1.6),
              paddingBottom: theme.spacing(1.6),
            }}
          />

          {child_item.colorPreview && (
            <Box
              sx={{
                width: 24,
                height: 24,
                backgroundColor: child_item.colorPreview,
                marginRight: theme.spacing(0.6),
              }}
            />
          )}
          {child_item.colorPreviewSecondary && (
            <Box
              sx={{
                width: 24,
                height: 24,
                backgroundColor: child_item.colorPreviewSecondary,
                marginRight: theme.spacing(1.25),
              }}
            />
          )}
          <NavigateRightIcon
            display={child_item.child_pages.length === 0 ? 'none' : 'block'}
          />
        </ListItem>
      ))}
    </div>
  );
};
const DrawerItems = ({
  menu_item,
  updateDrawer,
  navigate_to_page,
  handleThemeChange,
}) => {
  if (menu_item && menu_item == 'main_section') {
    return (
      <List>
        {drawer_sections.map((item, index) => (
          <div key={item.name + '_' + index}>
            <MenuSectionHeader header_title={item.name} />
            <MenuSectionItems
              section_items={item.child_pages}
              updateDrawer={updateDrawer}
              parentName={item.name}
              handleThemeChange={handleThemeChange}
            />
            <Divider />
          </div>
        ))}
      </List>
    );
  } else {
    if (menu_item.child_pages.length == 0) {
      navigate_to_page(menu_item.path);
      return;
    } else {
      return (
        <List>
          <MenuReturnHeader
            header_title={'Main Menu'}
            parentPageNavigationID="main_section"
            updateDrawer={updateDrawer}
          />
          <Divider />
          <MenuSectionHeader header_title={menu_item.name} />
          <MenuSectionItems
            section_items={menu_item.child_pages}
            updateDrawer={updateDrawer}
            parentName={menu_item.name}
            handleThemeChange={handleThemeChange}
          />
        </List>
      );
    }
  }
};

const CustomDrawer = () => {
  const { setTheme } = useContext(ThemeContext);

  const navigate = useNavigate();

  const handleThemeChange = (themeName) => {
    localStorage.setItem('selectedTheme', themeName); // Save theme choice

    switch (themeName) {
      case 'light':
        setTheme(lightTheme);
        break;
      case 'dark':
        setTheme(darkTheme);
        break;
      case 'blueTeam':
        setTheme(blueTeamTheme);
        break;
      case 'redTeam':
        setTheme(redTeamTheme);
        break;
      case 'purpleTeam':
        setTheme(purpleTeamTheme);
        break;
      default:
        setTheme(lightTheme);
    }
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('selectedTheme');
    if (savedTheme) {
      handleThemeChange(savedTheme);
    }
  }, []);

  const [open, setDesktopDrawOpen] = useState(() => {
    return false;
  });

  const toggleDrawer = () => {
    setDesktopDrawOpen((open) => !open);
  };

  const navigate_to_page = (location) => {
    setDesktopDrawOpen(false);
    location.startsWith('http')
      ? window.location.assign(location)
      : navigate(location);
  };

  const [draweritems, loadDrawerItems] = useState(() => {
    return <div></div>;
  });

  const updateDrawer = (menu_item) => {
    loadDrawerItems(
      <DrawerItems
        menu_item={menu_item}
        updateDrawer={updateDrawer}
        navigate_to_page={navigate_to_page}
        handleThemeChange={handleThemeChange}
      />,
    );
  };

  useEffect(() => {
    if (open === true) {
      updateDrawer('main_section');
    }
  }, [open]);

  // common header
  const drawerIcon = (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ mr: 0, display: { xs: 'block', sm: 'block' } }}
      onClick={toggleDrawer}
    >
      <MenuIcon />
    </IconButton>
  );

  return (
    <>
      {drawerIcon}
      <Drawer
        anchor="left"
        open={open}
        variant="temporary"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          backgroundColor: 'white',
          height: '0%',
        }}
        onClose={toggleDrawer}
      >
        <DrawerHeader onClick={toggleDrawer} header_title="Crushing Security" />
        {draweritems}
      </Drawer>
    </>
  );
};

DrawerHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  header_title: PropTypes.string.isRequired,
};

NavigateRightIcon.propTypes = {
  display: PropTypes.string.isRequired,
};

NavigateLeftIcon.propTypes = {
  display: PropTypes.string.isRequired,
};

MenuSectionHeader.propTypes = {
  header_title: PropTypes.string.isRequired,
};

MenuReturnHeader.propTypes = {
  header_title: PropTypes.string.isRequired,
  parentPageNavigationID: PropTypes.string.isRequired,
  updateDrawer: PropTypes.func.isRequired,
};

MenuSectionItems.propTypes = {
  section_items: PropTypes.array.isRequired,
  updateDrawer: PropTypes.func.isRequired,
  parentName: PropTypes.string.isRequired,
  handleThemeChange: PropTypes.func,
};

DrawerItems.propTypes = {
  menu_item: PropTypes.object.isRequired,
  updateDrawer: PropTypes.func.isRequired,
  navigate_to_page: PropTypes.func.isRequired,
  handleThemeChange: PropTypes.func,
};

export default CustomDrawer;
